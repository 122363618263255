.base {
  font-size: 12px;
  background: #FFF;
  border: 1px solid #555;
  border-radius: 3px;
  padding: 10px;
  padding-bottom: 0px;
  text-align: center;
}

.cable {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.cable:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.cable-foreignobject body {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
