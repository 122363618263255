.vertical-timeline-element-date {
    color: black;
}

.vertical-timeline::before {
    background: #cdcdcd;
}

.timeline-icon {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -10px;
    margin-top: -8px;
}

.timeline-add-button {
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.timeline-add-button:hover {
    background-color: #002c4e !important;
}

.timeline-scrollable {
    overflow-y: scroll;
    height:inherit;
}