.handleLabel {
  padding-top: 5px;
  position: absolute;
  transform: translate(-50%, 0);
  font-size: 8px;
}

.handleLabelTop {
  @extend .handleLabel;
  padding-top: 0px;
  top: 0px;
}

.nodeText {
  word-wrap: break-word;
  display: block;
  line-height: 1.1em;
  height: 3em;
}

.nodeSubText {
  font-size: 0.7em;
}

.expansionButtonContainer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.infoButton {
  color: #002c4e;
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  left: 10%;
  cursor: pointer;
}

.infoButton:hover {
  color: #007bff;
  background-color: transparent;
  border: none;
}

.infoButton:focus {
  color: #007bff;
  background-color: transparent;
  border: none;
}

.infoButton:active {
  color: #007bff !important;
  background-color: transparent !important;
  border: none !important;
}

.buttonRow {
  height: 10px;
}

/*.addButton {
  color: #ffffff;
  background-color: #002c4e;
  border: none;
  position:absolute;
  left: 10%;
  cursor: pointer;
}

.deleteButton {
  color: #ffffff;
  background-color: #dc3545;
  border: none;
  position:absolute;
  right: 10%;
  cursor: pointer;
}
*/
.deleteButton:hover {
  color: #ffffff;
  background-color: #dc3545;
  border: none;
}

.trashButton {
  color: #dc3545;
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 10%;
  cursor: pointer;
}

.trashButton:hover {
  color: #dc3545;
  background-color: transparent;
  border: none;
}

.trashButton:focus {
  color: #dc3545;
  background-color: transparent;
  border: none;
}

.trashButton:active {
  color: #dc3545 !important;
  background-color: transparent !important;
  border: none !important;
}

.base {
  font-size: 12px;
  background: #FFF;
  border: 1px solid #555;
  border-radius: 3px;
  padding: 10px;
  padding-bottom: 0px;
  text-align: center;
}

.react-flow__node-source {
  @extend .base;
  padding-bottom: 0px;
  width: 100px;
}

.react-flow__node-target {
  @extend .base;
  padding-bottom: 0px;
  width: 100px;
}
  
.react-flow__node-pid {
  @extend .base;
  padding-bottom: 0px;
  width: 100px;
}

.react-flow__node-linearEquation {
  @extend .base;
  padding-bottom: 0px;
  width: 100px;
}

.react-flow__node-pump {
  @extend .base;
  padding-bottom: 0px;
  width: 100px;
}

.react-flow__node-blended {
  @extend .base;
  padding-bottom: 0px;
  width: 100px;
}

.cable {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.cable:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.cable-foreignobject body {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

