/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #002c4e;
    border-color: #1861ac;
}

/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #002c4e;
    border-color: #1861ac;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    font-family: 'Poppins';
    position: relative;
    min-height: 100vh;
    padding-top: 105px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    line-height: 60px; /* Vertically center the text there */
}

#content-wrap {
    padding-bottom: 60px;
}

.card-body {
    padding: 60px;
}

.autochart-body {
    padding: 40px;
}

.smaller {
    font-size: 70%;
}

#insightGauges canvas {
    min-height: 200px;
    max-height: 200px;
    max-width: 300px;
}

#insightCharts canvas {
    min-height: 300px;
    max-height: 300px;
}

#insightScatterCharts canvas {
    min-height: 350px;
    max-height: 350px;
}

#insightBarCharts canvas {
    min-height: 350px;
    max-height: 350px;
}

#insightWidgets canvas {
    min-height: 120px;
    max-height: 120px;
}

#trendCharts canvas {
    min-height: 240px;
    max-height: 240px;
}

.no-data-blur-effect {
    filter: blur(6px) grayscale(80%) opacity(80%);
    -webkit-filter: blur(6px) grayscale(80%) opacity(80%);
}

.no-data-text {
    position: absolute;
    left: 47%;
    top: 43%;
}

.no-swa-text {
    position: absolute;
    top: 35%;
}


.active {
    background-color: #4faed6;
}

fa-2x {
    font-size: 2em;
}

#loginform {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

#registerform {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.dropdown-menu {
    max-height: 450px;
    overflow-y: auto;
}

.deltagaugecanvas {
    width: 100% !important;
    height: auto !important;
    z-index: 1;
}

.upsellinggaugecanvas {
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
}


.sitedetailsbargraph {
    width: 100% !important;
    height: auto !important;
}

.gaugecomponent {
    z-index: -1;
}

.sitedetailsinfobutton {
    opacity: 0.8;
    z-index: 1000;
    margin-left: 82%;
}

    .sitedetailsinfobutton:hover {
        opacity: 1;
        z-index: 1000;
    }

.verticalalign {
    margin-top: auto;
    margin-bottom: auto;
}

.nodataverticalalign {
    margin-top: 18%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.verticalline {
    border-left: 0.5px solid #ccc;
    margin-top: 0;
    margin-bottom: 0;
}

.legendsquare {
    border: 2px solid #101820;
    height: 12px !important;
    width: 12px !important;
}

.addjartestbutton {
    opacity: 0.8;
}

    .addjartestbutton:hover {
        opacity: 1;
        cursor: pointer;
    }

.chatbutton {
    position: fixed;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.5)
}

    .chatbutton:hover {
        cursor: pointer;
        background-color: white;
    }

.chatbox {
    position: fixed;
    right: 0px;
    bottom: 61px;
    height: 640px;
    width: 360px;
    background-color: rgba(255, 255, 255, 0.9);
}

.chatbox:hover {
    background-color: white;
}


.chatboxinput {
    bottom: 0px;
    height: 36px;
}

.chatboxheadline {
    cursor: pointer;
    position: fixed;
    background: white;
    width: 100%;
}

.notificationpill:hover {
    background-color: #f7f7f7;
}


.sitespecificnotificationslider {
    -webkit-appearance: none;
    width: 40px;
    height: 16px;
    background: #d3d3d3;
    outline: none;
    border-radius: 8px;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.sitespecificnotificationslider:hover {
    opacity: 1;
}

.sitespecificnotificationslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    border-radius: 10px;
    height: 20px;
    background: #002c4e;
    cursor: pointer;
}

.sitespecificnotificationslider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #002c4e;
    cursor: pointer;
}

.alarmrow {
    margin-bottom: 2px;
    margin-top: 2px;
}

.notificationrow {
    margin-bottom: 2px;
    margin-top: 2px;
}

.unreadnotification {
    background-color: rgba(24, 97, 172, 0.25);
}

.notificationrow:hover {
    background-color: rgba(24, 97, 172, 0.75);
    cursor: pointer;
    color: aliceblue;
}

.sortableHelper {
    z-index: 10000 !important;
}

.border-primary {
    border-color: #002c4e75 !important;
}

.bg-primary {
    background-color: #002c4e !important;
}

.loading-canvas {
    margin: auto;
    animation: spin 1s infinite cubic-bezier(0.7, 0.4, 0.5, 0.7);
    max-width: 100%;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

.fixed-buttons {
    top: 115px;
    justify-content: center;
    position: sticky;
    z-index: 1015;
    padding: 25px;
}

.chart-side-button {
    height: 100%;
    max-width: 42px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.modal-90w {
    width: 70vw;
    max-width: none !important;
}

.gauge-header {
    display: flex;
    min-height: 63px;
    justify-content: center;
}

.gauge-body {
    min-height: 185px;
    display: inline-grid;
    align-content: center;
}

.widget-header {
    display: flex;
    min-height: 63px;
    justify-content: center;
}

.widget-body {
    min-height: 60px;
}

.fixed-help-button {
    top: 115px;
    right: 30px;
    position: fixed;
    z-index: 1016;
}

.mt-n5 {
    margin-top: -3rem !important;
}

.icon-spin-reverse {
    -moz-animation: spin 2s infinite linear reverse;
    -o-animation: spin 2s infinite linear reverse;
    -webkit-animation: spin 2s infinite linear reverse;
    animation: spin 2s infinite linear reverse;
}